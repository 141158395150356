var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guanggao padT15" },
    [
      _c(
        "div",
        { staticClass: "x-bc typeList" },
        _vm._l(_vm.typeList, function (item, index) {
          return _c("div", { key: index, staticClass: "y-c item cursorP" }, [
            _c(
              "div",
              {
                staticClass: "iconBox x-c",
                class: [_vm.data.style.type == item.type ? "iconBox2" : ""],
                on: {
                  click: function ($event) {
                    return _vm.clickType(item.type, index)
                  },
                },
              },
              [
                _c("iconpark-icon", {
                  attrs: {
                    name: item.name,
                    size: "28px",
                    color:
                      _vm.data.style.type == item.type ? "#1458cc" : "#333333",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "marT10",
                style: {
                  color:
                    _vm.data.style.type == item.type ? "#1458cc" : "#333333",
                },
              },
              [_vm._v(_vm._s(item.title))]
            ),
          ])
        }),
        0
      ),
      _c("el-divider"),
      _c("div", { staticClass: "addImg" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "marT20" },
          [
            _vm._l(_vm.data.style.imgList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "el-card item padd15 y-c marB20" },
                [
                  _c("div", { staticClass: "row x-start" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "imgBox x-c cursorP",
                          on: {
                            click: function ($event) {
                              return _vm.addImgClick(index)
                            },
                          },
                        },
                        [
                          !item.image
                            ? _c("div", { staticClass: "y-c" }, [
                                _c("i", {
                                  staticClass: "el-icon-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    "margin-bottom": "5px",
                                  },
                                }),
                                _c("span", [_vm._v("添加图片")]),
                              ])
                            : item.image
                            ? _c(
                                "div",
                                {
                                  staticClass: "x-fc",
                                  staticStyle: {
                                    height: "100%",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    style: {
                                      width: item.width + "px",
                                      height: item.height + "px",
                                    },
                                    attrs: { src: item.image },
                                    on: {
                                      load: function ($event) {
                                        return _vm.setImageWidth(item)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.data.style.type == "twoSwiper"
                        ? _c(
                            "div",
                            {
                              staticClass: "imgBox marT10 cursorP",
                              on: {
                                click: function ($event) {
                                  return _vm.addImgClick(index, 2)
                                },
                              },
                            },
                            [
                              !item.bgImage
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "y-c",
                                      staticStyle: {
                                        height: "100%",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-plus",
                                        staticStyle: {
                                          "font-size": "20px",
                                          "margin-bottom": "5px",
                                        },
                                      }),
                                      _c("span", [_vm._v("添加背景图片")]),
                                    ]
                                  )
                                : item.bgImage
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "x-fc",
                                      staticStyle: {
                                        height: "100%",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        style: {
                                          width: item.bgWidth + "px",
                                          height: item.bgHeight + "px",
                                        },
                                        attrs: { src: item.bgImage },
                                        on: {
                                          load: function ($event) {
                                            return _vm.setImageWidth(item, 2)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "inputBox marL10 x-f1 y-start" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "x-b",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("div", [_vm._v("链接设置")]),
                            _vm.data.style.imgList.length > 1
                              ? _c("div", { staticClass: "x-f" }, [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-delete marL10 cursorP",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteListItem(index)
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _c("el-input", {
                          staticClass: "marT10 marB10",
                          attrs: {
                            size: "small",
                            placeholder: "图片提示信息（非必填）",
                          },
                          model: {
                            value: item.hint,
                            callback: function ($$v) {
                              _vm.$set(item, "hint", $$v)
                            },
                            expression: "item.hint",
                          },
                        }),
                        item.name
                          ? _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                placeholder: "选择链接",
                                readonly: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleChange(index)
                                },
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            })
                          : _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                placeholder: "选择链接",
                                readonly: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleChange(index)
                                },
                              },
                              model: {
                                value: item.path,
                                callback: function ($$v) {
                                  _vm.$set(item, "path", $$v)
                                },
                                expression: "item.path",
                              },
                            }),
                        _c("div"),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            _c("div", { staticClass: "addBtnBox x-c" }, [
              _c(
                "div",
                {
                  staticClass: "addBtn x-f cursorP",
                  on: { click: _vm.addBtn },
                },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _c("span", [_vm._v("添加图片")]),
                  _c("span", [
                    _vm._v(
                      "( " + _vm._s(_vm.data.style.imgList.length) + " /10 )"
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          2
        ),
      ]),
      _c("el-divider"),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              attrs: { model: _vm.data, "label-position": "left" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.data.style.type == "roll"
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "x-f" }, [
                        _vm._v("屏幕显示数量"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "图片样式", "label-width": "80px" } },
                        [
                          _c(
                            "div",
                            { staticClass: "x-bc" },
                            [
                              _c("div"),
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.setImgNum },
                                  model: {
                                    value: _vm.data.style.imgNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data.style, "imgNum", $$v)
                                    },
                                    expression: "data.style.imgNum",
                                  },
                                },
                                _vm._l(_vm.imgNumList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-divider"),
                    ],
                    1
                  )
                : _vm._e(),
              ["swiper", "roll", "twoSwiper"].includes(_vm.data.style.type)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "切换速度", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-bc" },
                        [
                          _c("div"),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.data.style.interval,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "interval", $$v)
                                },
                                expression: "data.style.interval",
                              },
                            },
                            _vm._l(_vm.intervalList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.type != "twoSwiper"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "圆角样式", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("el-slider", {
                            staticStyle: { width: "300px" },
                            attrs: { max: 100, "show-input": "" },
                            on: {
                              change: function ($event) {
                                return _vm.selectborder(
                                  _vm.data.style.borderRadius,
                                  "radius"
                                )
                              },
                            },
                            model: {
                              value: _vm.data.style.borderRadius,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "borderRadius", $$v)
                              },
                              expression: "data.style.borderRadius",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.type != "twoSwiper"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上边距", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("el-slider", {
                            staticStyle: { width: "300px" },
                            attrs: { max: 100, "show-input": "" },
                            model: {
                              value: _vm.data.style.top,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "top", $$v)
                              },
                              expression: "data.style.top",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.type != "twoSwiper"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "下边距", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("el-slider", {
                            staticStyle: { width: "300px" },
                            attrs: { max: 100, "show-input": "" },
                            model: {
                              value: _vm.data.style.bottom,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "bottom", $$v)
                              },
                              expression: "data.style.bottom",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.type != "twoSwiper"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "左右边距", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("el-slider", {
                            staticStyle: { width: "300px" },
                            attrs: { max: 100, "show-input": "" },
                            model: {
                              value: _vm.data.style.pageBackGauge,
                              callback: function ($$v) {
                                _vm.$set(_vm.data.style, "pageBackGauge", $$v)
                              },
                              expression: "data.style.pageBackGauge",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "y-start" }, [
      _c("span", [_vm._v("添加图片")]),
      _c("span", { staticStyle: { color: "#ababab" } }, [
        _vm._v("建议图片尺寸宽度750，高度不限"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }