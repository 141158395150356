<template>
  <div class="guanggao padT15">
    <div class="x-bc typeList">
      <div class="y-c item cursorP" v-for="(item, index) in typeList" :key="index">
        <div class="iconBox x-c" :class="[data.style.type == item.type ? 'iconBox2' : '']"
          @click="clickType(item.type, index)">
          <iconpark-icon :name="item.name" size="28px"
            :color="data.style.type == item.type ? '#1458cc' : '#333333'"></iconpark-icon>
        </div>
        <span class="marT10"
          :style="{ color: data.style.type == item.type ? '#1458cc' : '#333333' }">{{ item.title }}</span>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="addImg">
      <div class="y-start">
        <span>添加图片</span>
        <span style="color: #ababab">建议图片尺寸宽度750，高度不限</span>
      </div>
      <div class="marT20">
        <div class="el-card item padd15 y-c marB20" v-for="(item, index) in data.style.imgList" :key="index">
          <div class="row x-start">
            <div>
              <div class="imgBox x-c cursorP" @click="addImgClick(index)">
                <div class="y-c" v-if="!item.image">
                  <i class="el-icon-plus" style="font-size: 20px; margin-bottom: 5px"></i>
                  <span>添加图片</span>
                </div>
                <div v-else-if="item.image" style="height:100%;width:100%" class="x-fc">
                  <el-image :src="item.image" @load="setImageWidth(item)"
                    :style="{width:item.width+'px',height:item.height+'px'}"></el-image>
                </div>
              </div>
              <div class="imgBox marT10 cursorP" v-if="data.style.type == 'twoSwiper'" @click="addImgClick(index,2)">
                <div class="y-c" v-if="!item.bgImage" style="height:100%;width:100%">
                  <i class="el-icon-plus" style="font-size: 20px; margin-bottom: 5px"></i>
                  <span>添加背景图片</span>
                </div>
                <div v-else-if="item.bgImage" style="height:100%;width:100%" class="x-fc">
                  <el-image :src="item.bgImage" @load="setImageWidth(item,2)"
                    :style="{width:item.bgWidth+'px',height:item.bgHeight+'px'}"></el-image>
                </div>
              </div>
            </div>
            <div class="inputBox marL10 x-f1 y-start">
              <div class="x-b" style="width: 100%">
                <div>链接设置</div>
                <div class="x-f" v-if="data.style.imgList.length > 1">
                  <i class="el-icon-delete marL10 cursorP" @click="deleteListItem(index)"></i>
                </div>
              </div>
              <el-input class="marT10 marB10" size="small" v-model="item.hint" placeholder="图片提示信息（非必填）"></el-input>
              <el-input v-if="item.name" class="" style="width: 100%" size="mini" v-model="item.name"
                placeholder="选择链接" @focus="handleChange(index)" readonly />

                 <el-input v-else class="" style="width: 100%" size="mini" v-model="item.path"
                placeholder="选择链接" @focus="handleChange(index)" readonly />
              <div>
                <!--                                <el-radio v-model="radio" label="1">整体跳转</el-radio>-->
                <!--                                <el-radio v-model="radio" label="2">热区跳转</el-radio>-->
              </div>
            </div>
          </div>
        </div>
        <div class="addBtnBox x-c">
          <div class="addBtn x-f cursorP" @click="addBtn">
            <i class="el-icon-plus"></i>
            <span>添加图片</span>
            <span>( {{ data.style.imgList.length }} /10 )</span>
          </div>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div>
      <el-form :model="data" @submit.native.prevent label-position="left">
        <div v-if="data.style.type == 'roll'">
          <div class="x-f">屏幕显示数量</div>
          <el-form-item label="图片样式" label-width="80px">
            <div class="x-bc">
              <div></div>
              <el-select v-model="data.style.imgNum" @change="setImgNum" placeholder="请选择">
                <el-option v-for="item in imgNumList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-divider></el-divider>
        </div>
        <el-form-item label="切换速度" label-width="80px" v-if="['swiper', 'roll','twoSwiper'].includes(data.style.type)">
          <div class="x-bc">
            <div></div>
            <el-select v-model="data.style.interval" placeholder="请选择">
              <el-option v-for="item in intervalList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <!-- <el-form-item label="图片样式" label-width="80px">
                    <div class="x-bc">
                        <div class="x-f"></div>
                        <div class="x-f btnListBox">
                            <div
                                class="item x-c"
                                v-for="(item, index) in imgStyleList"
                                :key="index"
                                @click="setImgStyle(item.type, index)"
                                :class="{ borderColor: data.style.imgStyle == item.type}"
                            >
                                <span class="fontS12">{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </el-form-item> -->

        <el-form-item label="圆角样式" label-width="80px" v-if="data.style.type != 'twoSwiper'">
          <div class="x-f">
            <el-slider style="width: 300px" :max="100" @change="selectborder(data.style.borderRadius, 'radius')"
              v-model="data.style.borderRadius" show-input></el-slider>
          </div>
          <!-- <div class="x-bc">
                        <div class="x-f"></div>
                        <div class="x-f btnListBox">
                            <div
                                class="item x-c"
                                v-for="(item, index) in cornerStyleList"
                                :key="index"
                                @click="setCornerStyle(item.type, index)"
                                :class="{ borderColor: data.style.cornerStyle == item.type }"
                            >
                                <span class="fontS12">{{ item.name }}</span>
                            </div>
                        </div>
                    </div> -->
        </el-form-item>
        <el-form-item label="上边距" label-width="80px" v-if="data.style.type != 'twoSwiper'">
          <div class="x-f">
            <el-slider style="width: 300px" :max="100" v-model="data.style.top" show-input></el-slider>
          </div>
        </el-form-item>
        <el-form-item label="下边距" label-width="80px" v-if="data.style.type != 'twoSwiper'">
          <div class="x-f">
            <el-slider style="width: 300px" :max="100" v-model="data.style.bottom" show-input></el-slider>
          </div>
        </el-form-item>
        <el-form-item label="左右边距" label-width="80px" v-if="data.style.type != 'twoSwiper'">
          <div class="x-f">
            <el-slider style="width: 300px" :max="100" v-model="data.style.pageBackGauge" show-input></el-slider>
          </div>
        </el-form-item>
        <!-- <el-form-item
                    label="图片边距"
                    label-width="80px"
                    v-if="data.style.type == 'singleton'"
                >
                    <div class="x-f">
                        <el-slider
                            style="width: 300px"
                            :max="30"
                            v-model="data.style.imgbackGuge"
                            show-input
                        ></el-slider>
                    </div>
                </el-form-item> -->
      </el-form>
    </div>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import Material from '@/views/components/material/index.vue' //素材库
export default {
  name: 'guanggao',
  components: {
    Material,
    selectLink
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      selectImgType: 1,
      openLink: false, // 选择链接弹窗
      showDialog: false, // 是否显示图库弹框
      typeList: [
        { name: 'yihangyige', title: '一行一个', type: 'singleton' },
        { name: 'duotulunbo', title: '轮播海报', type: 'swiper' },
        { name: 'hengxianghuadong', title: '横向滚动', type: 'roll' },
        { name: 'duotulunbo', title: '双层轮播', type: 'twoSwiper' },
      ],
      imgNumList: [
        { value: 1, label: '一张图片' },
        { value: 2, label: '二张图片' },
        { value: 3, label: '三张图片' },
        { value: 4, label: '四张图片' },
        { value: 5, label: '五张图片' }
      ],
      intervalList: [
        { value: 1000, label: '1秒' },
        { value: 2000, label: '2秒' },
        { value: 3000, label: '3秒' },
        { value: 4000, label: '4秒' },
        { value: 5000, label: '5秒' }
      ],
      imgStyleList: [
        { name: '常规', type: 'original' },
        { name: '投影', type: 'projection' }
      ],
      cornerStyleList: [
        { name: '方角', type: 'square' },
        { name: '圆角', type: 'round' }
      ],
      options: [], // 跳转链接数据

      pathBtn: null,
      pathBtn2: null,
      typeItem: 0,
      imgStyleItem: 0,
      cornerItem: 0,
      addImgItem: null, // 添加图片时记录的列表下标
      selectImgIndex: null, // 选择图片的数组下标
      accessUrlList: [], // 选择的图片
      imgListIndex: null, // 标记选择链接的ITEN
    }
  },
  methods: {
    setImageWidth(item, type = 1) {
      let img = new Image(); // 创建一个新的Image对象
      img.onload = () => {
        let drawWidth = 100; // 默认绘制宽度
        let drawHeight = 100; // 默认绘制高度
        const imageWidth = img.width;
        const imageHeight = img.height;
        // 计算等比例缩放的尺寸
        const aspectRatio = imageWidth / imageHeight;
        if (aspectRatio > 1) {
          // 图片更宽，高度等比例缩放
          drawHeight = drawWidth / aspectRatio;
        } else {
          // 图片更高或正方形，宽度等比例缩放
          drawWidth = drawHeight * aspectRatio;
        }
        if (type == 1) {
          item.width = drawWidth
          item.height = drawHeight
        } else {
          item.bgWidth = drawWidth
          item.bgHeight = drawHeight
        }
      };
      img.src = item.image
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
    clickType(type, index) {
      console.log('选择的样式：', type)
      this.data.style.type = type
      this.typeItem = index
      this.templateMsg()
    },
    // 添加图片
    addBtn() {
      if (this.data.style.imgList.length >= 10) {
        return this.$message.warning('添加数量已到上限')
      }
      this.data.style.imgList.push({
        image: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png', // 图片链接
        bgImage: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png', // 图片链接
        hint: '', // 图片提示信息
        path: '' // 跳转链接
      })
      this.templateMsg()
    },
    // 鼠标移入
    over(index) {
      console.log('---', index)
      // this.pathBtn = index
    },
    // 鼠标移出
    leave(index) {
      console.log('---2', index)
      // this.pathBtn = null
    },
    // 鼠标移入
    over2(index) {
      // this.pathBtn2 = index
    },
    // 鼠标移出
    leave2(index) {
      // this.pathBtn2 = null
    },
    // 删除图片列表
    deleteListItem(index) {
      this.data.style.imgList.splice(index, 1)
    },
    // 选择图片显示数量
    setImgNum(val) {
      console.log('图片选择数量:', val)
    },
    // 图片样式
    setImgStyle(type, index) {
      this.data.style.imgStyle = type
      this.imgStyleItem = index
      this.templateMsg()
    },
    // 圆角数值
    selectborder(type, index) {
      this.templateMsg()
    },
    // 边角样式
    setCornerStyle(type, index) {
      console.log('边角样式:', type)
      this.data.style.cornerStyle = type
      this.cornerItem = index
      this.templateMsg()
    },
    // 添加图片
    addImgClick(index, type = 1) {
      this.selectImgIndex = index
      this.selectImgType = type
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value) {
      console.log('选择的图片：', value)
      this.accessUrlList = value

    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      if (this.selectImgType == 2) {
        this.data.style.imgList[this.selectImgIndex].bgImage = this.accessUrlList[0]
      } else {
        this.data.style.imgList[this.selectImgIndex].image = this.accessUrlList[0]
      }
      this.accessUrlList = []
      this.showDialog = false
      this.templateMsg()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    // 选择跳转链接改变事件
    handleChange(index) {
      console.log('选链接的item', index)
      this.imgListIndex = index
      this.openLink = true
    },
    // 选择链接
    selectLinkObj(value) {
      console.log('选择的链接', value)
      this.data.style.imgList[this.imgListIndex].path = value.url
      this.data.style.imgList[this.imgListIndex].name = value.name
      this.templateMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
.typeList {
  width: 100%;
  padding: 0 30px;

  .item {
    color: #989898;

    .iconBox {
      width: 60px;
      height: 60px;
      border: 1px solid #e5e5e5;
      padding: 20px;
      background: #f5f5f5;
    }

    .iconBox2 {
      border: 1px solid #1458cc;
      background: #d5e2f3;
    }

    span {
      width: 50px;
    }
  }
}

.addImg {
  .item {
    .row {
      width: 100%;

      .imgBox {
        width: 100px;
        height: 100px;
        border: 1px solid #e5e5e5;
        color: #155bd4;
        font-size: 12px;
        background: #f2f4f6;
      }

      .urlBtn {
        background: #1c75f4;
        color: #ffffff;
      }

      .selectPath {
        width: 130px;
        height: 330px;
        background: #ffffff;
        border-radius: 5px;
        padding: 5px;
        box-shadow: 0 5px 5px 5px #ababab;
      }
    }
  }

  .addBtnBox {
    width: 100%;

    .addBtn {
      padding: 10px 20px;
      background: #eeeeee;
    }
  }
}

.lable {
  margin-right: 16px;
  font-size: 14px;
  color: #d3d3d3;
  line-height: 18px;
  white-space: nowrap;
}

.btnListBox {
  border-radius: 5px;

  .item {
    width: 48px;
    height: 30px;
    border: 1px solid #ebedf0;
    //padding: 0 20px;
    cursor: pointer;
  }

  .borderColor {
    border: 1px solid #1c75f4;
  }
}
</style>
